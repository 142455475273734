export default defineI18nConfig(() => ({
    legacy: false,
    locale: 'de-CH',
    messages: {
        de_CH: {
            welcome: 'Willkommen'
        },
        fr_CH: {
            welcome: 'Bienvenue'
        },
        it_CH: {
            welcome: 'Ciao'
        }
    }
}));
